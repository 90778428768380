import 'jquery-jcrop'

export function crop_the_image(face_recognition,ratio,original_w,original_h) {
  $(document).on("turbolinks:load",function(){
    var screen_width = $('#cropbox').width();
    $('#cropbox').data('original_w', original_w);
    $('#cropbox').data('original_h', original_h);

    var crop_initial = [screen_width/4,0,screen_width/2,screen_width/2];
    if(face_recognition)
    {
      crop_initial = [screen_width/10,screen_width/10,(screen_width/10)*9,(screen_width/10)*9]
    }

    $('#cropbox').Jcrop({
      setSelect: crop_initial,
      onChange: update_crop,
      onSelect: update_crop,
      aspectRatio: ratio,
    });
  });
}
function update_crop(coords) {
  var screen_width = $('#cropbox').width();
  var screen_height = $('#cropbox').height();
  var original_width = $('#cropbox').data('original_w');
  var original_height = $('#cropbox').data('original_h');

  var final_x = (original_width / screen_width) * coords.x;
  var final_w = (original_width / screen_width) * coords.w;
  var final_h = final_w;
  var final_y = (original_height / screen_height) * coords.y;
  
  $("#crop_x").val(Math.round(final_x));
  $("#crop_y").val(Math.round(final_y));
  $("#crop_w").val(Math.round(final_w));
  $("#crop_h").val(Math.round(final_h));
}