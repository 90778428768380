export function display_error(msg)
{	if(msg) $('.merror p').html(msg);
	$(".merror").click(function() {
			$(".merror, .merror p").stop().fadeOut(500,function() {
			});
		});
	$(".merror, .merror p").stop(true,true);	
	$(".merror").css('height', 'auto');
	$(".merror p").show();
	var barh = $(".merror").height();
	$(".merror p").hide();
	$(".merror").delay(200).show().animate({height: barh},500, function() {
		$(".merror p, .message_img").fadeIn(500);
		$(".merror p, .message_img").delay(10000).fadeOut(500, function() {
				$(".merror").slideUp(1500,function() {
					//$(".merror").css({height: 'auto'});
				});
				
		});
	});	
};

export function display_notice(msg)
{	if(msg) $('.flash_success > p').html(msg);
	$(".flash_success ").hover(function() {
		$(".flash_success").stop().fadeOut(600);
	});
	$(".flash_success, .flash_success p").stop(true,true);	
	$(".flash_success").css('height', 'auto');
	$(".flash_success p").show();
	var barh = $(".flash_success").height();
	$(".flash_success p").hide();
	$(".flash_success").delay(200).show().animate({height: barh},500, function() {
		$(".flash_success p").fadeIn(500);
		$(".flash_success p").delay(3000).fadeOut(1000, function() {
				$(".flash_success").slideUp(1500,function() {	
				});
		});
	});	
}