import { initTimeline } from "packs/custom_js/bs4_horizontal_timeline"
import { display_error } from "packs/custom_js/alerts"
import { display_notice } from "packs/custom_js/alerts"
$(document).on("turbolinks:load",function(){
  


  var timelines = $('.cd-horizontal-timeline');
  (timelines.length > 0) && initTimeline(timelines);

  //wait_complete to complete
  var selector = $('.edit_imove_request > input#imove_request_state[value="wait_complete"], .edit_function > input#function_state[value="wait_complete"]') 
  if(selector.length > 0)
  { setTimeout(() => {  tryNext(); }, 6000);
    function tryNext( )
    { $(selector).closest('form').submit();
    }
  }

  $('#function_GetCustomer_request_attributes_inputable_attributes_same_fiscal_code').click(function(){
    var val = $(this).is(":checked");
    $('#function_GetCustomer_request_attributes_inputable_attributes_user_given_fiscal_code').prop('disabled',!val ? false : 'disabled')
  });

  if( (timelines = $('span.export_computing')).length > 0)
  { setTimeout(() => {  location.reload(); }, 5000);
  }

  //FILTRO RICERCHE
  $('.js-show-index-filters').click(function(){
    //$(".filter-well").slideToggle();
    $("#search-form").slideToggle();
    
    $(this).parents(".filter-wrap").toggleClass("collapsed");    
    //$("#expanded_searching").val( $("#expanded_searching").val() == 'true' ? 'false' : 'true');
    $("span.fa", $(this)).toggleClass("fa-angle-right");
    $("span.fa", $(this)).toggleClass("fa-angle-down");
  });
  // submit auto,matico nella ricerca veloce
  $(".js-quick-search").click(function(){
      $(this).select();
  });
  $(".js-quick-search").val($(".js-quick-search-target").val());
  // Catch the quick search form submit and submit the real form
  $("#quick-search").submit(function(){
    $(".js-quick-search-target").val($(".js-quick-search").val());
    $("#search-form form").submit();
    return false;
  });
  $(".js-submit-filter").click(function(){
    $(".js-quick-search-target").val($(".js-quick-search").val());
    $("#search-form form").submit();
    return false;
  });
  //cancella ricerca
  $(".js-trash-filter").click(function(){
    $(".js-quick-search-target").val(null);
    console.log($(this).closest('form'));
    $('form.search_fields').find('input:not([type=hidden]):not([type=submit]),select').val(null);
    $("#submit_searching").remove();
    $("#search-form form").submit();
    return false;
  });

  //disable all buttons insdie form on submit
  $("button.disabler").closest('form').on('submit', function(e) {
      $(this).find("button.disabler").css({"font-weight": "bold","text-decoration": "underline"});
      $('form.'+$(this).attr('class')).find("button.disabler").prop("disabled", true);
  });
  //datepicker
  $('.datepicker').datepicker(
    { language: 'it',
      constrainInput: false
  });

  //file selector file name
  $('.file_selector').on('change',function(e){
    var fileName = e.target.files[0].name;
    $(this).next('.custom-file-label').html(fileName);
  });
  //messaggi di errore
  $(".flash_success p").each(function() {
    
    if(!(($(this).html()) === "")) 
    { display_notice($(this).html());
    }
  });
  $(".merror p").each(function() {
    if(!(($(this).html()) === "")) 
    { display_error($(this).html());
    }
  });
  //DELETE ACTION LINK
  $('body').on('click', '.delete-resource', function() {
    var el = $(this);
    if (confirm(el.data("confirm"))) {
      $.ajax({
        type: 'POST',
        url: $(this).prop("href"),
        data: {
          _method: 'delete',
        },
        dataType: 'script',
        success: function(response) {
          el.parents("tr").fadeOut('hide', function() {
              $(this).remove();
          });
        },
        error: function(response, textStatus, errorThrown) {
          //show_flash('error', response.responseText);
          display_error(response.responseText);
        }
      });
    }
    return false;
  });


  $('.uses_spinner').click(function(e){
    var that = this;
    e.preventDefault();
    var buttonText = that.text
    $(that).addClass('disabled').prop("disabled",true).html('<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"> </span> Attendere prego ...')
    var req = new XMLHttpRequest();
    req.open("GET", that.getAttribute("href"), true);
    req.setRequestHeader('Content-type', 'application/form-data');
    req.responseType = "blob";
    req.onload = function (event) {
      var blob = req.response;
      var filename = event.currentTarget.getResponseHeader('Content-Disposition').split('filename=')[1].split(';')[0];
      var link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = filename.replace(/['"]+/g, '');
      link.click();
      $(that).removeClass('disabled').removeAttr("disabled").html($('<i></i>',{class: "fas fa-download"}));
      that.innerHTML= that.innerHTML + buttonText;
    };
    req.send();
  })
});