import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"

import 'jquery'
import 'popper.js'
import 'bootstrap'
import 'bootstrap-datepicker'
import "bootstrap-datepicker/dist/css/bootstrap-datepicker3.standalone.css"
import "bootstrap-datepicker/dist/locales/bootstrap-datepicker.it.min.js"
import "@fortawesome/fontawesome-free/css/all"
import 'jquery-jcrop'
import 'select2'
import 'select2/dist/css/select2.css'
import 'select2-theme-bootstrap4/dist/select2-bootstrap'

import "packs/custom_js/bs4_horizontal_timeline"
import "packs/custom_js/imageid_crop"
import "packs/custom_js/imageid_pickface"
import "packs/custom_js/global_var.js"
import "packs/custom_js/alerts"
import "packs/custom_js/runtime"
import "packs/custom_js/cards/locality_autocomplete"
import "packs/custom_js/cards/stop_autocomplete"

import 'css/bootstrap_import'
import 'css/bootstrap_configuration'
import 'css/layout'
import 'css/messages'
import 'css/rectangles'
import 'css/messages'
import 'css/bs4_horizontal_timeline'
import 'css/select2_configuration'
import 'jquery-jcrop/css/jquery.Jcrop.css'

Rails.start()
Turbolinks.start()
ActiveStorage.start()