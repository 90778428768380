$(document).on("turbolinks:load",function(){
  if ($(".stop_search").length > 0) {
    $(".stop_search").each(function(){
      that = this;
      $(this).select2({
        theme: 'bootstrap',
        multiple: false,
        dropdownAutoWidth : true
      });
    });   
  }
});