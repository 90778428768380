$(document).on('select2:open', () => {
    let allFound = document.querySelectorAll('.select2-container--open .select2-search__field');
    allFound[allFound.length - 1].focus();
});

$(document).on("turbolinks:load",function(){
  if ($(".locality_search").length > 0) {
    $(".locality_search").each(function(){
      var that = this;
      $(that).on('select2:clear', function (e) {
        var dest_select = $('#'+$(that).data('referred-id'))
        $(dest_select).empty();
        $(dest_select).prop( "disabled", true);
      });

      $(that).on('select2:select', function (e) {
          var data = e.params.data;
          var dest_select = $('#'+$(that).data('referred-id'))
          $.ajax({
            url: $(that).data('stop-source'),
            datatype: 'json',
            cache: true,
            data: {
              zone_id: data.id,
            },
            success: function(data) {
              $(dest_select).empty();
              $(dest_select).select2({theme: 'bootstrap',
                                      data: data,
                                      });
              $(dest_select).prop( "disabled", data.length == 0);
            }
          })
      });

      $(this).prepend('<option></option>').select2({
        theme: 'bootstrap',
        multiple: $(this).hasClass('multiple'),
        placeholder: 'Immetti almeno due caratteri',
        dropdownAutoWidth : true,
        minimumInputLength: 2,
        language: {
          noResults: function(){
            return "Nessun risultato valido!";
          },
          inputTooShort: function() {
            return 'Immetti almeno 2 caratteri';
          }
        },
        initSelection: function (element, callback) {
          $.ajax({
            dataType: "json",
            url: $(that).data('source'),
            data: {
              q: {id_in: element.val()}, 
            },
            success: function(data) {
              return callback(data);
            }
          });
        },
        ajax: {
          url: $(this).data('source'),
          datatype: 'json',
          cache: true,
          data: function(params) {
            return {
              q: {
                nome_cont: params.term,
                s: "nome asc"  
              }
            };
          },
          processResults: function(data,params) {
            return { results: data };
          }
        },
      });
    });
  }
});